import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { Member, MemberSummary, MemberSummaryList } from '../../../models/Member';
import FixedRatioBox from '../../atoms/FixedRatioBox';
import { FromNowWithInterval } from '../../atoms/FromNow/FromNow';
import { Marquee } from '../../atoms/Marquee';
import { SkinFace } from '../../atoms/SkinFace';
import { useIsMobile, useWindowSize } from '../../hooks';

export type ConfigurableMemberStatusOptions = {
  rows?: number;
  gap?: number;
  padding?: number;
  groupNameFontSize?: number;
  memberNameFontSize?: number;
  directVideoLink?: boolean;
  disableOfflineMemberClick: boolean;
  showPremiereLabel: boolean;
  showScheduleLabel: boolean;
  offlineVisibility?: 'all' | 'scheduled' | 'hidden';
  offlineStyle?: 'normal' | 'grayscale';
  showEmptyOnlineGroup?: boolean;
  offlineGroupName?: string;
};

type MemberStatusProps = {
  className?: string;
  members: MemberSummaryList;
};

type ConfigurableMemberStatusProps = {
  className?: string;
  members: MemberSummaryList;
  options?: ConfigurableMemberStatusOptions;
};

export const MemberStatus: React.FC<MemberStatusProps> = React.memo(({ className, members }) => {
  const isMobile = useIsMobile();
  const { width: clientWidth } = useWindowSize('client');
  const rows = isMobile ? 6 : Math.floor(window.innerWidth / 120);
  const style = {
    '--client-width': `${clientWidth}px`,
    '--rows': rows,
    '--padding': `${isMobile ? 8 : 16}px`,
    '--gap': `${isMobile ? 8 : 16}px`,
    '--group-name-font-size': '20px',
    '--member-name-font-size': '12px',
    '--placeholder-font-size': '24px',
    '--extra-label-font-size': `${clientWidth / rows / 7.5}px`,
  } as React.CSSProperties;

  return (
    <Wrapper className={className} style={style}>
      <MemberStatusCompoenent
        members={members}
        directVideoLink={true}
        disableOfflineMemberClick={false}
        showPremiereLabel={true}
        showScheduleLabel={true}
        offlineVisibility="all"
        offlineStyle="grayscale"
      />
    </Wrapper>
  );
});

export const ForeverMemberStatus: React.FC<MemberStatusProps> = React.memo(({ className }) => {
  const isMobile = useIsMobile();
  const { width: clientWidth } = useWindowSize('client');
  const rows = isMobile ? 6 : Math.floor(window.innerWidth / 120);
  const style = {
    '--client-width': `${clientWidth}px`,
    '--rows': rows,
    '--padding': `${isMobile ? 8 : 16}px`,
    '--gap': `${isMobile ? 8 : 16}px`,
    '--group-name-font-size': '20px',
    '--member-name-font-size': '12px',
    '--placeholder-font-size': '24px',
    '--extra-label-font-size': `${clientWidth / rows / 7.5}px`,
  } as React.CSSProperties;

  const members = MemberSummaryList.fromItemsJSON([
    {
      id: 'KazuChannel',
      name: '福井のカズ',
      shortName: '福井のカズ',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:59+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'maguro29',
      name: 'まぐにぃ',
      shortName: 'まぐにぃ',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:58+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'Dooozle',
      name: 'ドズル🦍',
      shortName: 'ドズル',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:57+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'bonj55',
      name: 'ぼんじゅうる🍆',
      shortName: 'ぼんじゅうる',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:56+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'KIOKIOoooo',
      name: 'きおきお🟢',
      shortName: 'きおきお',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:55+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'ooharaMEN',
      name: 'おおはらMEN🐷',
      shortName: 'おおはらMEN',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:54+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'taitai0904',
      name: 'たいたい🐯',
      shortName: 'たいたい',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:53+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'jajaankikuchi',
      name: 'じゃじゃーん菊池🎧',
      shortName: 'じゃじゃーん菊池',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:52+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'HikacGames',
      name: 'ヒカック',
      shortName: 'ヒカック',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:51+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'gizokk',
      name: 'ぎぞく',
      shortName: 'ぎぞく',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:50+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'messu009',
      name: 'メッス',
      shortName: 'メッス',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:49+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'Tyomu_Maro',
      name: 'まろ🐈',
      shortName: 'まろ',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:48+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'Qnly_qdm',
      name: 'おんりー🍌',
      shortName: 'おんりー',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:47+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'SAKAI_Thunder',
      name: 'さかいさんだー🐼',
      shortName: 'さかいさんだー',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:46+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'ORAMAINECRAF',
      name: 'おらふくん☃️',
      shortName: 'おらふくん',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:45+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'pom52_p',
      name: 'ぽんP',
      shortName: 'ぽんP',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:44+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'KOHALON',
      name: 'コハロン🌛',
      shortName: 'コハロン',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:43+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'Amaguri',
      name: '雨栗🤡',
      shortName: '雨栗',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:42+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'Luzaku',
      name: '水月ルザク',
      shortName: '水月ルザク',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:41+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'KOMESHOWGUN',
      name: '米将軍',
      shortName: '米将軍',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:40+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'kitakubu',
      name: '帰宅部トリオ',
      shortName: '帰宅部トリオ',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:39+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'maeyon',
      name: 'まえよん',
      shortName: 'まえよん',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:38+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: '3chunk',
      name: 'さんちゃんく！',
      shortName: 'さんちゃんく！',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:37+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
    {
      id: 'shyutan',
      name: 'タツナミ シュウイチ',
      shortName: 'タツナミ シュウイチ',
      status: 'offline',
      lastOnlineTime: '2025-03-22T15:59:36+00:00',
      lastLoginTime: '2025-03-22T11:00:00+00:00',
      liveVideoUrl: null,
      scheduledVideoUrl: null,
      scheduledStartTime: null,
      scheduledVideoType: null,
    },
  ]);
  return (
    <Wrapper className={className} style={style}>
      <MemberStatusCompoenent
        members={members}
        directVideoLink={true}
        disableOfflineMemberClick={false}
        showPremiereLabel={true}
        showScheduleLabel={true}
        offlineVisibility="all"
        offlineStyle="normal"
        showEmptyOnlineGroup={false}
        offlineGroupName="永遠のアツクラメンバー"
      />
    </Wrapper>
  );
});

export const ConfigurableMemberStatus: React.FC<ConfigurableMemberStatusProps> = React.memo(
  ({ className, members, options = {} }) => {
    const isMobile = useIsMobile();
    const defaultRows = isMobile ? 6 : Math.floor(window.innerWidth / 120);
    const {
      rows = defaultRows,
      gap = 2,
      padding = 2,
      groupNameFontSize = 4,
      memberNameFontSize = 1.6,
      directVideoLink = true,
      disableOfflineMemberClick = false,
      showPremiereLabel = true,
      showScheduleLabel = true,
      offlineVisibility = 'all',
      offlineStyle = 'grayscale',
    } = options;

    const { width: clientWidth } = useWindowSize('client');
    const style = {
      '--client-width': `${clientWidth}px`,
      '--rows': rows,
      '--padding': `${(clientWidth / 100) * padding}px`,
      '--gap': `${(clientWidth / 100) * gap}px`,
      '--group-name-font-size': `${(clientWidth / 100) * groupNameFontSize}px`,
      '--member-name-font-size': `${(clientWidth / 100) * memberNameFontSize}px`,
      '--placeholder-font-size': `${Math.max(12, (clientWidth / 100) * memberNameFontSize * 2)}px`,
      '--extra-label-font-size': `${clientWidth / rows / 7.5}px`,
    } as React.CSSProperties;

    const showGroupName = groupNameFontSize > 0;
    const showMemberName = memberNameFontSize > 0;

    return (
      <Wrapper className={className} style={style}>
        <MemberStatusCompoenent
          members={members}
          directVideoLink={directVideoLink}
          disableOfflineMemberClick={disableOfflineMemberClick}
          showPremiereLabel={showPremiereLabel}
          showScheduleLabel={showScheduleLabel}
          offlineVisibility={offlineVisibility}
          offlineStyle={offlineStyle}
          showGroupName={showGroupName}
          showMemberName={showMemberName}
        />
      </Wrapper>
    );
  },
);

const MemberStatusCompoenent: React.FC<
  MemberStatusProps &
    Pick<
      ConfigurableMemberStatusOptions,
      | 'directVideoLink'
      | 'disableOfflineMemberClick'
      | 'showPremiereLabel'
      | 'showScheduleLabel'
      | 'offlineVisibility'
      | 'offlineStyle'
      | 'showEmptyOnlineGroup'
      | 'offlineGroupName'
    > & {
      showGroupName?: boolean;
      showMemberName?: boolean;
    }
> = ({
  members,
  offlineVisibility,
  offlineStyle,
  showPremiereLabel,
  showScheduleLabel,
  directVideoLink,
  disableOfflineMemberClick,
  showGroupName = true,
  showMemberName = true,
  showEmptyOnlineGroup = true,
  offlineGroupName = 'オフライン',
}) => {
  const onlineMembers = members.onlineMembers.sortByLiveVideosLastLoginTime();
  const offlineMembers = members.offlineMembers.sortByLiveVideosLastOnlineTime();
  const soonOnlineMembers = members.soonOnlineMembers.sortByScheduledStartTime();

  const LinkContainer = useMemo(() => (directVideoLink ? YouTubeVideoLink : MemberPageLink), [directVideoLink]) as any;
  const paramConverter = useMemo(
    () =>
      directVideoLink
        ? (member: MemberSummary, type: 'live' | 'schedule') => ({
            href: type === 'live' ? member.liveVideoUrl || '' : member.scheduledVideoUrl || '',
            target: '_blank',
          })
        : (member: MemberSummary, type: 'live' | 'schedule') => ({
            to: `/members/${member.id}#${type}`,
            smooth: true,
            offset: -200,
          }),
    [directVideoLink],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTime, setCurrentTime] = useState(dayjs());
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <StatusContainer>
      {(onlineMembers.items.length > 0 || showEmptyOnlineGroup) && (
        <MemberGroup>
          {showGroupName && <Label>ライブ配信中</Label>}
          {onlineMembers.items.length > 0 ? (
            <SkinFaceList>
              {onlineMembers.items.map((member) => (
                <LinkContainer key={member.id} {...paramConverter(member, 'live')}>
                  <SkinFaceContainer>
                    <FixedRatioBox ratio={1}>
                      <SkinFace nameTag={member.id} status="online" />
                      {showPremiereLabel && member.status === 'premiere' && (
                        <PremiereLabel>
                          <Marquee>
                            <ExtraLabelMarqueeInner>プレミア公開中</ExtraLabelMarqueeInner>
                          </Marquee>
                        </PremiereLabel>
                      )}
                    </FixedRatioBox>
                    {showMemberName && (
                      <Marquee animationIterationCount={5}>
                        <MarqueeInner>{member.shortName}</MarqueeInner>
                      </Marquee>
                    )}
                  </SkinFaceContainer>
                </LinkContainer>
              ))}
            </SkinFaceList>
          ) : (
            <Placeholder>
              <BlockText>現在ライブ配信中の</BlockText>
              <BlockText>メンバーはいません</BlockText>
            </Placeholder>
          )}
        </MemberGroup>
      )}
      {(offlineVisibility === 'all' || (offlineVisibility === 'scheduled' && soonOnlineMembers.items.length > 0)) && (
        <MemberGroup>
          {showGroupName && <Label>{offlineVisibility === 'all' ? offlineGroupName : 'もうすぐ配信開始'}</Label>}
          <SkinFaceList>
            {soonOnlineMembers.items.map((member) => (
              <LinkContainer
                key={member.id}
                {...(disableOfflineMemberClick ? { to: '' } : paramConverter(member, 'schedule'))}
              >
                <SkinFaceContainer>
                  <FixedRatioBox ratio={1}>
                    <SkinFace nameTag={member.id} status={offlineStyle === 'grayscale' ? 'offline' : 'online'} />
                    {showScheduleLabel && member.isComingOnlineSoon && (
                      <ScheduleLabel>
                        <Marquee>
                          <ExtraLabelMarqueeInner>
                            <FromNowWithInterval
                              time={member.scheduledStartTime}
                              interval={1000}
                              beforeLabel="まもなく"
                            />
                          </ExtraLabelMarqueeInner>
                        </Marquee>
                        {member.scheduledVideoType === 'premiere' && (
                          <Marquee>
                            <ExtraLabelMarqueeInner>
                              <>プレミア公開</>
                            </ExtraLabelMarqueeInner>
                          </Marquee>
                        )}
                      </ScheduleLabel>
                    )}
                  </FixedRatioBox>
                  {showMemberName && (
                    <Marquee animationIterationCount={5}>
                      <MarqueeInner>{member.shortName}</MarqueeInner>
                    </Marquee>
                  )}
                </SkinFaceContainer>
              </LinkContainer>
            ))}
            {offlineVisibility === 'all' &&
              offlineMembers.items.map((member) => (
                <MemberPageLink key={member.id} to={disableOfflineMemberClick ? '' : `/members/${member.id}`}>
                  <SkinFaceContainer>
                    <FixedRatioBox ratio={1}>
                      <SkinFace nameTag={member.id} status={offlineStyle === 'grayscale' ? 'offline' : 'online'} />
                    </FixedRatioBox>
                    <Marquee animationIterationCount={5}>
                      <MarqueeInner>{member.shortName}</MarqueeInner>
                    </Marquee>
                  </SkinFaceContainer>
                </MemberPageLink>
              ))}
          </SkinFaceList>
        </MemberGroup>
      )}
    </StatusContainer>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: -16px;
  margin-right: -16px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  height: 100%;
  padding-left: var(--padding);
  padding-right: var(--padding);
`;

const Label = styled.div`
  font-size: var(--group-name-font-size);
  font-weight: bold;
  line-height: 3;
`;
const MemberGroup = styled.div``;
const SkinFaceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
`;
const SkinFaceContainer = styled.div`
  width: calc((var(--client-width) - var(--padding) * 2 - var(--gap) * calc(var(--rows) - 1)) / var(--rows));

  cursor: pointer;
  :hover {
    img {
      filter: none;
    }
  }
  user-select: none;
`;
const MemberPageLink = styled(HashLink)``;
const YouTubeVideoLink = styled.a``;

const MarqueeInner = styled.span`
  font-size: var(--member-name-font-size);
  font-weight: bold;
  color: #f1f1f1;
  white-space: nowrap;
  display: block;
  position: relative;
  line-height: 1.4;
`;

const Placeholder = styled.div`
  padding: calc(var(--placeholder-font-size) * 0.5) 0;
  text-align: center;
`;

const BlockText = styled.div`
  display: inline-block;
  font-size: var(--placeholder-font-size);
`;

const PremiereLabel = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 5%;
  background: rgba(204, 0, 0, 0.9);
`;

const ScheduleLabel = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 5%;
  background: rgba(0, 0, 204, 0.9);
`;

const ExtraLabelMarqueeInner = styled.span`
  font-size: var(--extra-label-font-size);
  font-weight: bold;
  color: #f1f1f1;
  white-space: nowrap;
  display: block;
  position: relative;
  padding: 0 4px;
`;
