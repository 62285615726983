import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import { AtsukuraLiveData } from '../../../models/AtsukuraLiveData';
import { Stats } from '../../../models/Stats';
import { Arrow } from '../../atoms/Arrow';
import { BlockText } from '../../atoms/BlockText';
import { EmptyPlaceholder } from '../../atoms/EmptyPlacefolder';
import { withVisibilitySensor } from '../../hocs';
import { useLocalStorage } from '../../hooks/storage';
import { Recommendation } from '../../molecules/Recommendation';
// import { AdMax } from '../../molecules/ad/AdMax';
// import { AdWrapper } from '../../molecules/ad/AdWrapper';
import { AtsukuraStats } from '../../organisms/AtsukuraStats';
import { ForeverMemberStatus } from '../../organisms/MemberStatus/MemberStatus';
import { SupportRequest } from '../../organisms/SupportRequest';
import { VideoCardList } from '../../organisms/VideoCardList';
import { AutoLimitVideoCardListLoader } from '../../organisms/VideoCardListLoader';

type TopPageTemplateProps = {
  className?: string;
  data: AtsukuraLiveData;
  kirinukiStats: Stats;
};

const SupportRequestWithVisibilitySensor = withVisibilitySensor({})(SupportRequest);
export const TopPageTemplate: React.FC<TopPageTemplateProps> = ({ className, data, kirinukiStats }) => {
  const [displayRecommendation, setDisplayRecommendation] = useLocalStorage<boolean>(
    'DISPLAY_RECOMMENDATION',
    true,
    false,
  );

  return (
    <Wrapper id="top" className={className}>
      <FirstView>
        <Title>アツクラLIVE!</Title>
        <Caution style={{ textAlign: 'center' }}>2025年4月から1日1回のみ更新、2025年末まで予定</Caution>
        <MemberStatusWrapper>
          <ForeverMemberStatus members={data.members} />
        </MemberStatusWrapper>
      </FirstView>
      <CautionContainer>
        {/* <Caution>※ 状態の反映は最大10分程度遅れることがあります</Caution>
        <LinkContainer>
          <Link to="live">
            <Caution style={{ textDecoration: 'underline' }}>LIVEモード</Caution>で表示
          </Link>
        </LinkContainer> */}
      </CautionContainer>
      <Separator />
      <Section id="live">
        <GakuenMapLinkContainer>
          <GakuenMapThumbnail src="/images/gakuenmap/atsukura_gakuen_thumbnail.png" />
          <div style={{ minWidth: 210 }}>
            アツクラ学園マップ配布中！
            <br />
            ダウンロードは
            <a
              href="https://minecraft-mcworld.com/106315/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              こちら
            </a>
            から！
          </div>
        </GakuenMapLinkContainer>
      </Section>
      <Separator />
      <Section id="support">
        <SupportRequestWithVisibilitySensor />
      </Section>

      {displayRecommendation && (
        <MobileOnlySection id="recommendation">
          <Separator />
          <Recommendation setDisplayRecommendation={setDisplayRecommendation} />
        </MobileOnlySection>
      )}
      {/* <Section id="ad1">
        <AdWrapper>
          <AdMax target="mobile" spot="SP_トップ下" />
          <AdMax target="pc" spot="PC_トップ下" />
        </AdWrapper>
      </Section> */}
      <Separator />
      <Section id="stats">
        <StatsContainer>
          <AtsukuraStats stats={data.stats} kirinukiStats={kirinukiStats} />
        </StatsContainer>
      </Section>
      <Separator />
      <Section id="live">
        <SectionTitle>ライブ配信中の動画</SectionTitle>
        <VideoCardListWrapper>
          {data.liveVideos.items.length > 0 ? (
            <VideoCardList videos={data.liveVideos.items} disableAds={true} />
          ) : (
            <StyledEmptyPlaceholder>
              <BlockText>現在ライブ配信中の</BlockText>
              <BlockText>動画はありません</BlockText>
            </StyledEmptyPlaceholder>
          )}
        </VideoCardListWrapper>
      </Section>
      <Separator />
      <Section id="schedule">
        <SectionTitle>配信予定の動画</SectionTitle>
        <VideoCardListWrapper>
          {data.upcomingVideos.items.length > 0 ? (
            <VideoCardList videos={data.upcomingVideos.items} disableAds={true} />
          ) : (
            <StyledEmptyPlaceholder>
              <BlockText>配信予定の</BlockText>
              <BlockText>動画のありません</BlockText>
            </StyledEmptyPlaceholder>
          )}
        </VideoCardListWrapper>
      </Section>
      {/* <Section id="ad2">
        <AdWrapper>
          <AdMax target="mobile" spot="SP_トップ下" />
        </AdWrapper>
      </Section> */}
      <Separator />
      <Section id="archive">
        <SectionTitle>ライブ配信アーカイブ</SectionTitle>
        <VideoCardListWrapper>
          <AutoLimitVideoCardListLoader
            key="published/live"
            memberIdOrAllOrForMembers="all"
            videoTypeOrVideoStatus="published/live"
            limitItemsForMobile={2}
            emptyPlaceholder={<EmptyPlaceholder>ライブ配信アーカイブはありません</EmptyPlaceholder>}
            moreLink={
              <>
                <MoreLinkMobile to="/videos?type=archive">ライブ配信アーカイブ をもっと見る</MoreLinkMobile>
                <MoreLinkPC id="more-link-published-live" to="/videos?type=archive">
                  <Arrow direction="down" weight={2} />
                </MoreLinkPC>
                <Tooltip anchorId="more-link-published-live" place="bottom">
                  もっと見る
                </Tooltip>
              </>
            }
          />
        </VideoCardListWrapper>
      </Section>
      <Separator />
      <Section id="video">
        <SectionTitle>公開中の動画</SectionTitle>
        <VideoCardListWrapper>
          <AutoLimitVideoCardListLoader
            key="published/video"
            memberIdOrAllOrForMembers="all"
            videoTypeOrVideoStatus="published/video"
            limitItemsForMobile={2}
            emptyPlaceholder={<EmptyPlaceholder>公開中の動画はありません</EmptyPlaceholder>}
            moreLink={
              <>
                <MoreLinkMobile to="/videos?type=video">公開中の動画 をもっと見る</MoreLinkMobile>
                <MoreLinkPC id="more-link-published-video" to="/videos?type=video">
                  <Arrow direction="down" weight={2} />
                </MoreLinkPC>
                <Tooltip anchorId="more-link-published-video" place="bottom">
                  もっと見る
                </Tooltip>
              </>
            }
          />
        </VideoCardListWrapper>
      </Section>
      <Separator />
      <Section id="kirinuki">
        <SectionTitle>切り抜き動画</SectionTitle>
        <VideoCardListWrapper>
          <AutoLimitVideoCardListLoader
            key="published"
            channelType="kirinuki"
            memberIdOrAllOrForMembers="all"
            videoTypeOrVideoStatus="published"
            limitItemsForMobile={2}
            emptyPlaceholder={<EmptyPlaceholder>切り抜き動画はありません</EmptyPlaceholder>}
            moreLink={
              <>
                <MoreLinkMobile to="/videos?ctype=kirinuki">切り抜き動画 をもっと見る</MoreLinkMobile>
                <MoreLinkPC id="more-link-kirinuki-video" to="/videos?ctype=kirinuki">
                  <Arrow direction="down" weight={2} />
                </MoreLinkPC>
                <Tooltip anchorId="more-link-kirinuki-video" place="bottom">
                  もっと見る
                </Tooltip>
              </>
            }
          />
        </VideoCardListWrapper>
      </Section>
      <Separator />
      <CautionContainer>
        <LinkContainer>
          <Link to="about">
            <Caution style={{ textDecoration: 'underline' }}>アツクラLIVEについて</Caution>
          </Link>
          <Link to="changelog">
            <Caution style={{ textDecoration: 'underline' }}>更新履歴</Caution>
          </Link>
        </LinkContainer>
      </CautionContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 60px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  margin: 12px 0;
`;
const CautionContainer = styled.div`
  text-align: right;
  margin-bottom: 24px;
  font-size: 14px;
  color: #999;
  a {
    font-size: 14px;
    color: #999;
  }
`;
const Caution = styled.div`
  display: inline-block;
  text-align: left;
`;
const FirstView = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 430px) {
    justify-content: start;
    margin-bottom: 84px;
    min-height: calc(100svh - 16px * 2 - 100px);
    max-height: 926px;
  }
  margin-bottom: 24px;
`;

const MemberStatusWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const Section = styled.div``;
const SectionTitle = styled.div`
  font-size: 20px;
`;

const StatsContainer = styled.div`
  margin-bottom: 24px;
`;

const VideoCardListWrapper = styled.div``;
const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 24px;
  border-color: #f1f1f130;
  margin-left: auto;
  margin-right: auto;
`;
const StyledEmptyPlaceholder = styled(EmptyPlaceholder)`
  margin: 24px 12px 48px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const MoreLinkMobile = styled(Link)`
  display: block;
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  @media screen and (min-width: 431px) {
    display: none;
  }
`;

const MoreLinkPC = styled(Link)`
  display: flex;
  justify-content: center;
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
  padding: 6px 0;
  @media screen and (max-width: 430px) {
    display: none;
  }
`;

const MobileOnlySection = styled(Section)`
  margin-bottom: 24px;
  display: none;
  @media screen and (max-width: 430px) {
    display: block;
  }
`;

const GakuenMapLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const GakuenMapThumbnail = styled.img`
  height: 160px;
  @media screen and (max-width: 430px) {
    height: 80px;
  }
`;
